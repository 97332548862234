import React, { FC } from 'react';
import { useIsShopSideLaunched } from '../../hooks/useIsShopSideLaunched';
import AboutPage from '../AboutPage/AboutPage';
import LandingPageV2 from '../LandingPageV2/LandingPageV2';
import { useEnabledCustomerExperiences } from '../../hooks/useEnabledCustomerExperiences';

const HomePage: FC = () => {
  const isShopSideLaunched = useIsShopSideLaunched();
  const { isShopClosed, allowBuy } = useEnabledCustomerExperiences();

  if (!isShopSideLaunched || isShopClosed || !allowBuy) {
    return <AboutPage />;
  }

  return <LandingPageV2 />;
};

export default HomePage;
